/**
 * Gallery
 */

module.exports = function () {

    var $gallery = $('#gallery');

    $(function () {
        if ($gallery.length) {
            render($gallery);
        }
    });

    function render($el) {
        $el.nanogallery2({
            thumbnailHeight                    : 400,
            thumbnailWidth                     : 'auto',
            thumbnailGutterWidth               : 2,
            thumbnailGutterHeight              : 2,
            thumbnailBorderHorizontal          : 0,
            thumbnailBorderVertical            : 0,
            thumbnailLabel                     : {'display' : false},
            thumbnailDisplayTransition         : 'scaleUp',
            thumbnailDisplayTransitionDuration : 500,
            thumbnailDisplayInterval           : 30,
            thumbnailHoverEffect2              : {
                name:           'imageScaleIn80',
                delay:          0,
                duration:       1400,
                easing:         'easeOutQuart',
                delayBack:      0,
                durationBack:   1000,
                easingBack:     'easeOutQuart',
                hoverin:        true,
                hoverout:       true,
                firstKeyframe:  true
            },
            viewerToolbar:    {
                standard:  '',
                minimized: ''
            },
            viewerTools:    {
                topLeft:   'pageCounter, playPauseButton, label',
                topRight:  'zoomButton, fullscreenButton, closeButton'
            },
            viewerTheme: 'border',
            galleryLastRowFull:  true
        });
    }
}
