/**
* Forms
*/

module.exports = function () {

    // Validation
    $("#form-contact").validate({
        ignore: ".ignore",
        rules: {
            name: {
                minlength: 3,
                required: true
            },
            email: {
                email: true
            },
            message: {
                required: true,
                minlength: 3
            },
            hiddenRecaptcha: {
                required: function () {
                    if (grecaptcha.getResponse() == '') {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
        messages: {
            hiddenRecaptcha: "Please ensure that you are a human!"
        },
        highlight: function(element) {
            $(element).addClass('is-invalid');
        },
        unhighlight: function(element) {
            $(element).removeClass('is-invalid');
            $(element).addClass('is-valid');
        },
        errorElement: 'span',
        errorClass: 'invalid-feedback mb-2',
        errorPlacement: function(error, element) {
            if (element.parent('.form-group').length) {
                error.insertAfter(element.parent());
            } else {
                error.insertAfter(element);
            }
        }
    });

}