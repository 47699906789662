/**
* Global
*/

module.exports = function () {

	// vars
	var $link = $('.a-link')
		, scrollTop = $(window).scrollTop();

	$(function() {

		// Redirect to url 
		$link.on('click', function(e) {
			e.preventDefault();
			let url = $(this).data('url');

			window.location.href = url;
		});

	}); // end DOM ready

}