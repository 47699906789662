/**
* Navbar
*/

module.exports = function () {

    $(function() {
        // If a link has a dropdown, add sub menu toggle.
        $('nav ul li a:not(:only-child)').click(function(e) {
            e.preventDefault();
            $(this).siblings('.nav-dropdown').toggle();
            // Close one dropdown when selecting another
            $('.nav-dropdown').not($(this).siblings()).hide();
                e.stopPropagation();
        });
        // Clicking away from dropdown will remove the dropdown class
        $('html').click(function() {
            $('.nav-dropdown').hide();
        });
        // Toggle open and close nav styles on click
        $('#nav-toggle').click(function() {
            $('nav ul').slideToggle();
        });
        // Hamburger to X toggle
        $('#nav-toggle').on('click', function() {
            this.classList.toggle('active');
        });

        // Hide navbar on scroll
        var current
            , currentScrollTop = 0
            , $nav = $('.hide-on-scroll');

        $(window).scroll(function () {
            var scrollTop = $(window).scrollTop();
            var height = $nav.height();

            currentScrollTop = scrollTop;

            if (current < currentScrollTop && scrollTop > height + height) {
                $nav.addClass('scroll-up');
            } else if (current > currentScrollTop && !(scrollTop <= height)) {
                $nav.removeClass('scroll-up');
            }
            current = currentScrollTop;

            // Show navbar if the user is at the top of the page
            if (scrollTop === 0) {
                $nav.removeClass('scroll-up');
            }
        });
    }); // end DOM ready

}