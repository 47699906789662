/**
* Animations
*/

module.exports = function () {

    $(function() {
        // Animate on scroll library
        AOS.init({
           easing: 'ease-out-back',
           duration: 2000,
           delay: 300,
           once: true,
           disable: 'mobile'
        });
    });

}